// Importazione del file parziale _variables.scss
@import "variables";
// Importazione del file SCSS di Bootstrap
@import "~bootstrap/scss/bootstrap";
// Importazione del file per i carousel Slick
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

//#region misc
* {
    outline: none !important;
    -webkit-font-smoothing: antialiased;
}
a.text-secondary:hover,
a.text-secondary:focus {
    color: $secondary !important;
}
::selection {
    background: $secondary;
    color: $white;
}
*::-webkit-scrollbar {
    width: 7px;
}
*::-webkit-scrollbar-track {
    background: $light;
    border: $white;
}
*::-webkit-scrollbar-thumb {
    background: $text-color;
    border-radius: 7px;
}
*::-webkit-scrollbar-thumb:hover {
    background: $primary;
}
//#endregion

//#region controls
.form-control {
    background-color: $light;
    border: none;
    border-radius: 5px;
    height: auto;
    padding: px-to-rem(15px) px-to-rem(20px);
    line-height: $leading-none;
    &:focus {
        box-shadow: none;
        background-color: darken($light, 5%);
    }
}
.btn {
    border-radius: 5px;
    height: auto;
    padding: px-to-rem(15px) px-to-rem(20px);
    line-height: $leading-none;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: none !important;
}
.btn-read {
    border-radius: 40px;
    text-transform: none;
    font-weight: $font-weight-medium;
    background-color: $white;
    &:hover {
        img {
            filter: invert(1) brightness(1000%);
        }
    }
}
.btn-product {
    border-radius: 0;
    text-transform: none;
    font-weight: $font-weight-medium;
    &:hover {
        img {
            filter: invert(1) brightness(1000%);
        }
    }
}
//#endregion

//#region typography
.title {
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    font-size: px-to-rem(28px);
}
.subtitle {
    text-transform: uppercase;
    font-size: px-to-rem(24px);
    font-weight: $font-weight-medium;
    line-height: $leading-none;
    &--alt {
        text-transform: none;
        font-size: px-to-rem(34px);
        &::after {
            content: '';
            display: block;
            width: 35px;
            height: 3px;
            background-color: $primary;
            margin-top: px-to-rem(10px);
            margin-bottom: px-to-rem(20px);
        }
        &.text-center {
            &::after {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}
.text {
    img {
        max-width: 100%;
        margin-bottom: px-to-rem(15px);
    }
}
.link-download {
    font-size: px-to-rem(14px);
    color: $secondary;
    font-weight: $font-weight-medium;
    display: inline-flex;
    align-items: center;
    text-decoration: none !important;
    &:hover {
        color: $secondary !important;
        background-color: $white !important;
    }
}
//#endregion

//#region topbar
.topbar {
    background-color: $light;
    padding: px-to-rem(5px) 0;
    &__menu {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        &--left {
            justify-content: flex-start;
        }
        &--right {
            justify-content: flex-end;
        }
        > li {
            > a {
                display: inline-block;
                padding: px-to-rem(10px);
                color: $text-color;
                font-size: px-to-rem(14px);
                font-weight: $font-weight-medium;
            }
            &:first-child {
                > a {
                    padding-left: 0;
                }
            }
            &:last-child {
                > a {
                    padding-right: 0;
                }
            }
        }
    }
}
//#endregion

//#region navbar
.navbar {
    padding-top: px-to-rem(20px);
    padding-bottom: px-to-rem(20px);
    .navbar-brand {
        @include media-breakpoint-down(md) {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .nav-item {
        @include media-breakpoint-down(md) {
            text-align: center;
        }
        .nav-link {
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            font-size: px-to-rem(14px);
            color: $text-color;
        }
        &:last-child {
            .nav-link {
                padding-right: 0;
            }
        }
    }
    &-toggler {
        display: inline-flex;
        padding: 0;
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}
//#endregion

//#region megamenu
.megamenu {
    position: absolute;
    z-index: 99;
    left: px-to-rem(15px);
    right: px-to-rem(15px);
    margin-top: px-to-rem(2px);
    background-color: $white;
    border: 1px solid $primary;
    padding: px-to-rem(10px) 0;
    display: none;
    @include media-breakpoint-down(md) {
        position: static;
    }
    &--show {
        display: block;
    }
    &__item {
        position: relative;
        &:hover {
            .megamenu__dropright {
                display: block;
            }
        }
    }
    &__link {
        display: block;
        text-transform: uppercase;
        padding: px-to-rem(10px) px-to-rem(20px);
        background-image: url(./../../images/ico-caret-right.svg);
        background-repeat: no-repeat;
        background-position: right 20px center;
        color: $text-color;
        font-size: px-to-rem(14px);
        line-height: $leading-none;
        &:hover {
            text-decoration: none;
            color: $primary;
        }
    }
    &__dropright {
        display: none;
        padding: px-to-rem(10px) 0;
        position: absolute;
        border: 1px solid darken($light, 10%);
        left: 100%;
        top: 0;
        z-index: 1;
        background-color: $white;
        @include media-breakpoint-down(md) {
            position: static;
            background-color: $light;
        }
        &--show {
            @include media-breakpoint-down(md) {
                display: block;
            }
        }
    }
    &__sublink {
        display: block;
        padding: px-to-rem(10px) px-to-rem(20px);        
        color: $text-color;
        font-size: px-to-rem(14px);
        line-height: $leading-none;
        white-space: nowrap;
        &:hover {
            text-decoration: none;
            color: $primary;
        }
    }
}
//#endregion

//#region footer
.footer {
    @include media-breakpoint-up(lg) {
        margin-top: px-to-rem(45px);
    }
    &__top {
        background-color: $light;
        padding: px-to-rem(15px) 0;
    }
    &__bottom {
        background-color: $dark;
        padding: px-to-rem(15px) 0;
        color: $white;
    }
    &__text {
        margin-bottom: 0;
        font-size: px-to-rem(12px);
    }
}
//#endregion

//#region slider
.slider {
    &__slide {
        img {
            width: 100%;
        }
    }
    &__arrow {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 1;
        &--prev {
            left: px-to-rem(30px);
        }
        &--next {
            right: px-to-rem(30px);
        }
    }
}
//#endregion

//#region box
.box {
    &__image {
        display: block;
        height: 220px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__title {
        text-transform: uppercase;
        color: $text-color;
        font-size: px-to-rem(21px);
        font-weight: $font-weight-medium;
        position: relative;
        margin-top: px-to-rem(20px);
        &::after {
            content: '';
            display: block;
            width: 35px;
            height: 3px;
            background-color: $primary;
            margin-top: px-to-rem(10px);
            margin-bottom: px-to-rem(20px);
        }
        > a {
            text-decoration: none !important;
        }
    }
    &__text {
        font-size: px-to-rem(15px);
        color: $text-color;
    }
}
//#endregion

//#region bordered
.bordered {
    border: 1px solid $border-color;
    padding: px-to-rem(15px);
    &__title {
        font-size: px-to-rem(21px);
        color: $primary;
        text-transform: uppercase;
        line-height: $leading-none;
        > a {
            color: $primary;
            text-decoration: none !important;
        }
    }
    &__text {
        font-size: px-to-rem(13px);
        color: $text-color;
    }
}
//#endregion

//#region event
.event {
    &__date {
        display: block;
        font-weight: $font-weight-bold;
        font-size: px-to-rem(12px);
    }
    &__title {
        color: $primary;
        text-transform: uppercase;
        font-size: px-to-rem(16px);
        font-weight: $font-weight-medium;
        > a {
            color: $primary;
            text-decoration: none !important;
        }
    }
    &__text {
        font-size: px-to-rem(13px);
    }
}
//#endregion

//#region ctas
.cta {
    display: block;
    padding: px-to-rem(20px);
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    text-decoration: none !important;
    &--left {
        background-image: url(./../../images/bg-cta-left.jpg);
        span {
            display: block;
            &.text-primary {
                font-size: px-to-rem(20px);
            }
            &.text-dark {
                font-size: px-to-rem(35px);
                font-weight: $font-weight-medium;
            }
        }
    }
    &--right {
        background-image: url(./../../images/bg-cta-right.jpg);
        span {
            display: block;
            font-size: px-to-rem(19px);
            font-weight: $font-weight-medium;
            &:first-child {
                text-transform: uppercase;
                font-size: px-to-rem(36px);
                font-weight: $font-weight-bold;
            }  
            &.btn {
                display: inline-flex;
                margin-top: px-to-rem(15px);
            }          
        }
    }
}
//#endregion

//#region breadcrumb 
.breadcrumb {
    background-color: transparent;
    border-top: 1px solid $border-color;
    padding-left: 0;
    padding-right: 0;
    &-item {
        font-size: px-to-rem(13px);
        color: $text-color;
        &.active {
            color: $text-color;
        }
        > a {
            color: $secondary;
        }
    }
}
//#endregion

//#region product
.product {
    padding-top: px-to-rem(30px);
    padding-bottom: px-to-rem(15px);
    &__image {
        text-align: center;
        padding: px-to-rem(20px);
        border: 1px solid $light;
        margin-bottom: px-to-rem(20px);
        text-align: center;
        img {
            display: inline-block;
            max-width: 100%;
        }
    }
    &__title {
        font-size: px-to-rem(18px);
        font-weight: $font-weight-bold;
        color: $secondary;
        text-transform: uppercase;
        margin-bottom: px-to-rem(20px);
        > a {
            text-decoration: none !important;
            color: $secondary;
        }
    }
    &__text {
        margin-top: px-to-rem(15px);
        margin-bottom: px-to-rem(15px);
        font-size: px-to-rem(14px);
    }
}
//#endregion